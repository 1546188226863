<template>
  <div class="weather">
    {{ newCity }}
    <div class="temp">{{ newWeather.temp.toFixed(0) }}°</div>
    <div>Feels Like {{ newWeather.feels_like.toFixed(0) }}°</div>
    <div>Humidity {{ newWeather.humidity.toFixed(0) }}%</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Weather",
  data() {
    return {
      patr: 1050,
      amount: 1
    };
  },
  methods: {
    ...mapActions(["getWeather"])
  },
  computed: mapGetters(["newWeather", "newCity"]),

  created() {
    this.getWeather();
  }
};
</script>

<style scoped>
.temp {
  border: 5px solid #0c090d;
  border-radius: 15px;
  width: 200px;
  font-size: 80px;
  margin: 0 auto;
  color: mix(#fff, #53b3cb, 85%);
  font-weight: 900;
  text-shadow: -0.0075em 0.0075em 0 mix(#fff, #53b3cb, 94%),
    0.005em 0.005em 0 mix(#fff, #53b3cb, 60%),
    0.01em 0.01em 0 mix(#fff, #53b3cb, 62%),
    0.015em 0.015em mix(#fff, #53b3cb, 64%),
    0.02em 0.02em 0 mix(#fff, #53b3cb, 66%),
    0.025em 0.025em 0 mix(#fff, #53b3cb, 68%),
    0.03em 0.03em 0 mix(#fff, #53b3cb, 70%),
    0.035em 0.035em 0 mix(#fff, #53b3cb, 72%);
}
</style>
